/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Oswald";
  src: url("assets/fonts/Oswald-Bold.ttf");
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

html,
body {
  min-height: 100%;
  min-width: 100%;
  /* background: rgb(83, 78, 99); */
}
